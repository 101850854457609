import React from 'react';
import { useTranslation } from 'react-i18next';

import ShopsLogo01 from '../../images/img-shops-logo01.png';
import ShopsLogo02 from '../../images/img-shops-logo02.png';
import ShopsLogo03 from '../../images/img-shops-logo03.png';
import ShopsLogo04 from '../../images/img-shops-logo04.png';
import ShopsLogo06 from '../../images/img-shops-logo06.png';
import ShopsLogo08 from '../../images/img-shops-logo08.png';
import ShopsLogo09 from '../../images/img-shops-logo09.png';

const SwiperShopsLogo = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="shop__logos">
        <div className="shop__logos-slider">
          <ul>
            <li>
              <img src={ShopsLogo01} alt="" />
            </li>
            <li>
              <img src={ShopsLogo02} alt="" />
            </li>
            <li>
              <img src={ShopsLogo03} alt="" />
            </li>
            <li>
              <img src={ShopsLogo04} alt="" />
            </li>
            <li>
              <img src={ShopsLogo06} alt="" />
            </li>
            <li>
              <img src={ShopsLogo08} alt="" />
            </li>
            <li>
              <img src={ShopsLogo01} alt="" />
            </li>
            <li>
              <img src={ShopsLogo02} alt="" />
            </li>
            <li>
              <img src={ShopsLogo03} alt="" />
            </li>
            <li>
              <img src={ShopsLogo04} alt="" />
            </li>
            <li>
              <img src={ShopsLogo06} alt="" />
            </li>
            <li>
              <img src={ShopsLogo08} alt="" />
            </li>
            <li>
              <img src={ShopsLogo09} alt="" />
            </li>
          </ul>
        </div>
        <div className="container">
          <h2 i18next-key="top-swipershopslogo-text">{t('top-swipershopslogo-text')}</h2>
        </div>
      </div>
    </div>
  );
};
export default SwiperShopsLogo;
