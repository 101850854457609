import GatsbyTemplate from './gatsby-template';
import Top from '../../frontend/components/top';
import { Shop, Category } from '../infrastructure/shop-service';
import { ResourceBundles } from '../infrastructure/language-service';

export type PageProps = {
  features: Shop[];
  categories: Category[];
  resourceBundles: ResourceBundles;
};

export default GatsbyTemplate<PageProps>(Top);
