import React from 'react';
import { useTranslation } from 'react-i18next';
import { paramCase } from 'change-case';

import Layout from '../common/layout';
import SwiperShopsLogo from './swiper-shops-logo';
import Customer from '../common/customer';
import SEO from '../common/seo';

import { PageProps } from '../../../server/templates/top';

import TopHowTo01 from '../../images/img-howto01.svg';
import TopHowTo02 from '../../images/img-howto02.svg';
import TopHowTo03 from '../../images/img-howto03.svg';
import TopHowTo04 from '../../images/img-howto04.svg';

import { initI18nextWithResourceBundles } from '../../utils/i18next-helper';
// import Banner from '../../images/wsbfcm2023.png';

const TopPage = ({ categories, resourceBundles }: PageProps): JSX.Element => {
  initI18nextWithResourceBundles(resourceBundles);
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title="Home" />
      <div className="hero">
        <div className="container">
          <div>
            <h1 className="linebreak" i18next-key="top-hero-title">
              {t('top-hero-title')}
            </h1>
            <p i18next-key="top-hero-text">{t('top-hero-text')}</p>
            <a href="/guide" className="btn fas fa-chevron-right" i18next-key="top-message-btntext">
              {t('top-message-btntext')}
            </a>
          </div>
          <div className="container__img" />
        </div>
      </div>

      {/*<div className="black_banner">*/}
      {/*  <a href={'https://campaign.worldshopping.global/BFCM2023?utm_source=worldshopping&utm_medium=ws&utm_campaign=wsg'}>*/}
      {/*    <img src={Banner} alt="" />*/}
      {/*  </a>*/}
      {/*</div>*/}

      <div className="howto">
        <div className="container">
          <h2 i18next-key="top-howto-title">{t('top-howto-title')}</h2>
          <p i18next-key="top-howto-text">{t('top-howto-text')}</p>
          <ul>
            <li>
              <img src={TopHowTo01} alt="" />
              <p>
                <strong i18next-key="top-howto-step01title">{t('top-howto-step01title')}</strong>
                <span i18next-key="top-howto-step01text">{t('top-howto-step01text')}</span>
              </p>
            </li>
            <li>
              <i className="fas fa-arrow-down" />
              <img src={TopHowTo02} alt="" />
              <p>
                <strong i18next-key="top-howto-step02title">{t('top-howto-step02title')}</strong>
                <span i18next-key="top-howto-step02text">{t('top-howto-step02text')}</span>
              </p>
            </li>
            <li>
              <i className="fas fa-arrow-down" />
              <img src={TopHowTo03} alt="" />
              <p>
                <strong i18next-key="top-howto-step03title">{t('top-howto-step03title')}</strong>
                <span i18next-key="top-howto-step03text">{t('top-howto-step03text')}</span>
              </p>
            </li>
            <li>
              <i className="fas fa-arrow-down" />
              <img src={TopHowTo04} alt="" />
              <p>
                <strong i18next-key="top-howto-step04title">{t('top-howto-step04title')}</strong>
                <span i18next-key="top-howto-step04text">{t('top-howto-step04text')}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <SwiperShopsLogo />

      <div className="shop__pickup">
        <div className="container">
          <h2 i18next-key="top-shoppickup-title">{t('top-shoppickup-title')}</h2>
          <div id="shop__elfsight" className="elfsight-app-3d6ea74e-9691-4254-bc94-22ad5062c90b"></div>
        </div>
      </div>

      <div className="shop__category">
        <div className="container">
          <h2>Various Online Shops to Select From</h2>
          <ul className="shop__category-list">
            <li key="ALL" className={`category-all`}>
              <a href={`/categories/`} i18n-key={`category-all`}>
                {t(`category-all`)}
              </a>
            </li>
            {categories.map(category => (
              <li key={category.key} className={`category-${paramCase(category.key)}`}>
                <a href={`/categories/${paramCase(category.key)}`} i18n-key={`category-${paramCase(category.key)}`}>
                  {t(`category-${paramCase(category.key)}`)}
                </a>
              </li>
            ))}
          </ul>
          <a href="https://{{top-shoppickup-url}}" className="btn fas fa-chevron-right">
            <span i18next-key="top-shoppickup-btn">{t('top-shoppickup-btn')}</span>
          </a>
        </div>
      </div>

      <Customer />

      <div className="backtotop">
        <a href="#" className="fas fa-arrow-up"></a>
      </div>
    </Layout>
  );
};

export default TopPage;
